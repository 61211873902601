import React, {useState, useEffect} from 'react';
import './Client.css';
import {Row, Col} from 'react-bootstrap';
import Marquee from "react-fast-marquee";
import {motion} from 'framer-motion';
import axios from 'axios';





function Client() {

    const [posts, setPosts] = useState([]);


    useEffect(() => {
        axios.get('https://api.mobiusdigital.co.id/api/tech-stacks')
        .then(res => {
            console.log(res)
            setPosts(res.data.data)
        })
        .catch(err =>{
            console.log(err)
        })
    },[])


  return (
    <div className="client-section-body">
        <motion.div className="client-section-sub"
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1, y:0 },
          hidden: { opacity: 0, y:50}
        }}
        >
            Tech Stacks</motion.div>
        <motion.div className="client-section-title"
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1, y:0 },
          hidden: { opacity: 0, y:50}
        }}
        >
            Technology We Use</motion.div>

            <Marquee speed={70} gradient={false}>
            {posts.map(post => (
                <div key={post.id} className="client-img-place">
                    <img src={`https://api.mobiusdigital.co.id${post.image_path}`} alt=""/>
                </div>
            ))}
            </Marquee>




    </div>
  )
}

export default Client
