import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Home from './Pages/Home/Home';
import Portfolio from './Pages/Portfolio/Portfolio';
import PortfolioDetails from './Pages/PortfolioDetails/PortfolioDetails';

import Process from './Pages/Process/Process';
import About from './Pages/About/About';
import Career from './Pages/Career/Career';

import Blog from './Pages/Blog/Blog';
import BlogDetails from './Pages/BlogDetails/BlogDetails';

import Services from './Pages/Services/Services';
import ServicesDetails1 from './Pages/ServicesDetails/ServicesPerson/ServicesDetail1';
import ServicesDetails2 from './Pages/ServicesDetails/ServicesTeam/ServicesDetails2';
import ServicesDetails3 from './Pages/ServicesDetails/SaasDevelopment/ServicesDetail3';

import ScrollToTop from './Components/ScrollToTop';


import Contact from './Pages/Contact/Contact';



function App() {

  return (
          <Router>
            <ScrollToTop />
            <main>
              <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/portfoliodetails/:id" element={<PortfolioDetails />} />
              <Route path="/process" element={<Process />} />
              <Route path="/about" element={<About />} />
              <Route path="/career" element={<Career />} />
              <Route path="/services" element={<Services />} />
              <Route path="/services1" element={<ServicesDetails1 />} />
              <Route path="/services2" element={<ServicesDetails2 />} />
              <Route path="/services3" element={<ServicesDetails3 />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blogdetails/:id" element={<BlogDetails />} />
              <Route path="/contact" element={<Contact />} />

              </Routes>
            </main>
          </Router>
  );
}

export default App;
