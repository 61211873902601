import React from 'react';
import './Section1.css';
import {Row, Col} from 'react-bootstrap';
import AboutImage from '../../../../assets/about.png';
import {motion} from 'framer-motion';

function Section1() {
  return (
    <div className="about-section1-body">
        <Row className="g-0">
            <Col xs={12} sm={12} lg={6}>
            <motion.div className="about-image-place" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }}>
                <img src={AboutImage} alt="" className="about-image" />
            </motion.div>
            </Col>

            <Col xs={12} sm={12} lg={6}>
                <motion.div className="about-section1-section" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:50}
            }}>
                    <div className="about-section1-sub">
                    About Us
                    </div>
                    <div className="about-section1-title">
                    Mobius at a Glance
                    </div>

                    <div className="about-section1-text">
                    Mobius is an IT consulting & outsourcing company. We provide services to any business with wide-range from small to global scale by providing IT professional talents and agile-ready teams with flexible configurations in order to support our customer adapting to the ever-changing digital world. We have more than 100 dedicated IT professional talents. Everyday our team continuously grow larger.
                    </div>


                </motion.div>


            </Col>

        </Row>

    </div>
  )
}

export default Section1
