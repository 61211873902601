import React from 'react';
import './Footer.css';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Logo from '../../assets/logo_1.png';

function Footer() {
  return (
    <div className="footer-background">
    <div className="footer-body">

        <Row>
            <Col xs={12} sm={6} md={6} lg={5}>
            <div className="footer-logo-place">
                 <Link to="/"><img src={Logo} className="footer-logo" alt="" /></Link>   
                </div>
            <div className="footer-exp">
            Make future digital. Make yours too.</div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={2}>
            <div className="footer-quick-link">
            Quick Link
            </div>
                {/* <Link to="/about" className="footer-link-decoration">About Us</Link> */}
                <Link to="/contact" className="footer-link-decoration">Contact Us</Link>
                <Link to="/portfolio" className="footer-link-decoration">Portfolio</Link>
                <Link to="/career" className="footer-link-decoration">Career</Link>

            </Col>
            <Col xs={12} sm={6} md={6} lg={2}>
            <div className="footer-quick-link">
            Services
            </div>
                <Link to="/services" className="footer-link-decoration">Services</Link>
                <Link to="/process" className="footer-link-decoration">Process</Link>
                <Link to="/blog" className="footer-link-decoration">Blog</Link>
            </Col>
            <Col xs={12} sm={6} md={6} lg={3}>
            <div className="footer-quick-link">
            Contact Info
            </div>
            <div className="contact-info">
            The Mansion Bougenville Kemayoran<br/>
            Tower Fontana Fl. 50 D1<br/>
            Pademangan, Jakarta Utara<br/>
            DKI Jakarta - Indonesia<br/>
            14410
            </div>
            <div className="contact-info">
            <a href="mailto:admin@mobiusdigital.co.id" className="text-decoration-number"> admin@mobiusdigital.co.id  </a>          </div>
            <div className="contact-info">
            <a href="https://wa.me/6281510078373" className="text-decoration-number">+62 81510078373</a>           </div>
            </Col>
        </Row>

    </div>

    <hr className="footer"></hr>
    <div className="footer-bottom">
        <div className="footer-bottom-title">
            MOBIUS DIGITAL © All Rights Reserved</div>
      </div>
    </div>
  )
}

export default Footer
