import React, {useState, useEffect} from 'react';
import './ClientBlack.css';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';


function ClientBlack() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        axios.get('https://api.mobiusdigital.co.id/api/clients')
        .then(res => {
            console.log(res)
            setPosts(res.data.data)
        })
        .catch(err =>{
            console.log(err)
        })
    },[])

  return (
    <div className="clientblack-body">
        <motion.div className="clientblack-title"
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1, y:0},
          hidden: { opacity: 0, y:50}
        }}
        >Selected Past Clients</motion.div>
        <div id="grid-clientblack">
            {posts.map(post => (
                <div  key={post.id}>
                    <Link to="/portfolio">
                    <img className="clientblack-image" alt="" src={`https://api.mobiusdigital.co.id${post.image_path}`} />
                    </Link>
                </div>
            ))}
        </div>




    </div>
  )
}

export default ClientBlack
