import React, {useState, useEffect} from 'react';
import './ClientLight.css';
import axios from 'axios';

function ClientLight() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        axios.get('https://api.mobiusdigital.co.id/api/clients/non-porto')
        .then(res => {
            console.log(res)
            setPosts(res.data.data)
        })
        .catch(err =>{
            console.log(err)
        })
    },[])
  return (
    <div className="clientlight-body">
        <div className="clientlight-title">And Many Other Clients</div>
        <div id="grid-clientblack">
            {posts.map(post => (
                <div  key={post.id}>
                    <a href={`${post.website}`} target='_blank'>
                    <img className="clientlight-image" alt="" src={`https://api.mobiusdigital.co.id${post.image_path}`} />
                    </a>
                </div>
            ))}
        </div>

    </div>
  )
}

export default ClientLight
