import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './Section2.css';
import {Card, Button } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';


function Section2() {
    const [posts, setPosts] = useState([]);
    const [visible, setVisible] = useState(3);

    const loadMore = () => {
    setVisible(visible + 3)
  }

    useEffect(() => {
        axios.get('https://api.mobiusdigital.co.id/api/articles/All')
        .then(res => {
            console.log(res)
            setPosts(res.data.data)
        })
        .catch(err =>{
            console.log(err)
        })
    },[])



  return (

    <div className="blog-post-body">
        <div id="grid">
                {posts.slice(0, visible).map(post => (
                    <motion.div initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 0.8 }}
                    variants={{
                      visible: { opacity: 1, y:0},
                      hidden: { opacity: 0, y:50}
                    }}  >
                    <Card className="blog-posts" key={post.id}>
                        <Link to= {`/blogdetails/${post.id}`} className="text-decoration">
                        <div className="blog-post-date">
                            <div className="blog-date">{post.date}
                            <div className="blog-month">{post.month}</div>
                        </div>
                        </div>
                        <Card.Img variant="top" src={`https://api.mobiusdigital.co.id${post.thumbnail_path}`} />
                        <div className="blog-card-body">
                            <div className="blog-post-author">{post.author} </div>
                        <div className="blog-posts-title">{post.title}</div>
                        <div className="blog-post-text">
                            {post.caption}
                        </div>
                        </div>
                        </Link>
                    </Card>
                    </motion.div>

                ))}
        </div>

        <Button onClick={loadMore} className="section-btn-load-more">Load More</Button>

    </div>
  )
}

export default Section2
