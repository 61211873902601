import React from 'react';
import './Blog.css';

import Header from '../../Components/Navbar/Header';

import Section2 from './Components/Section2/Section2';
import AboutSection3 from '../About/Components/Section3/Section3';
import Footer from '../../Components/Footer/Footer';
import {motion} from 'framer-motion';

function Blog() {
  const variants = {
    visible: { opacity: 1, transition:{duration: 0.7, delay: 1} },
    hidden: { opacity: 0,  },
  };
  return (
    <div>
      <Header />
      <div className="blog-hero">
      <motion.div className="blog-title" initial="hidden" animate="visible" variants={variants}>Blog
        <hr className="home-section1-line"></hr>
        </motion.div>
      </div>

      <Section2 />
      <AboutSection3 />
      <Footer />




    </div>
  )
}

export default Blog
