import React from 'react';
import './Navigation.css';
import {Navbar, Nav} from 'react-bootstrap';
import Logo from '../../assets/logo_1.png';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';
import { Squash as Hamburger } from 'hamburger-react'



function Navigation() {
  const dropIn={
    hidden:{
      opacity: 0,

    },
    visible:{
      opacity:1,
      transition:{
        duration: 2, type: "spring",
      }

    },
    exit:{
      opacity:0,
      transition:{
        duration: 2, type: "spring",}
    },
  };

  const dropIn2={
    hidden:{
      opacity: 0,

    },
    visible:{
      opacity:1,
      transition:{
        duration: 2, type: "spring",
        delay:1,
      }

    },
    exit:{
      opacity:0,
      transition:{
        duration: 2, type: "spring",}
    },
  };

  return (
    <motion.div variants={dropIn} initial="hidden" animate="visible" exit="exit">
    <Navbar expand="lg" className="navbar-background">
      <div className="navigation-navigation-place">
        <Link to ="/"> 
        <img src={Logo} className="logo-navigation"  alt=""/>
        </Link>
      </div>
      
        <Navbar.Toggle>
          <span className="burger-color">
          <Hamburger />
          </span>
          </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav-placement ms-auto">
          <Link className="nav-link" to="/services">Services</Link>
           <Link className="nav-link" to="/process">Process</Link>
           <Link className="nav-link" to="/portfolio">Portfolio</Link>
           <Link className="nav-link" to="/blog">Blog</Link>
           {/* <Link className="nav-link" to="/about">About</Link> */}
           <Link className="nav-link" to="/career">Career</Link>
           <Link className="nav-link" to="/contact">Contact</Link>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
    </motion.div>
  )
}

export default Navigation
