import React, {useState} from 'react';
import './Section1.css';

import Section1Content from './Section1Content';
import Section1NavItem from './Section1NavItem';


import ProcessContent1 from './ProcessContent1';
import ProcessContent2 from './ProcessContent2';
import ProcessContent3 from './ProcessContent3';
import ProcessContent5 from './ProcessContent5';


import {AiOutlineFundProjectionScreen} from 'react-icons/ai';
import {TbConfetti} from 'react-icons/tb';
import {BiCode} from 'react-icons/bi';
import {GoRocket} from 'react-icons/go';

import {Row, Col} from 'react-bootstrap';

import {motion} from 'framer-motion';

function Section1() {
  const [activeTab, setActiveTab] = useState("tab1");

  const scroll1 = () => {
    const section = document.querySelector( '#section-1' );
    section.scrollIntoView( { behavior: 'smooth', block: 'center' } );
  };

  const scroll2 = () => {
    const section = document.querySelector( '#section-2' );
    section.scrollIntoView( { behavior: 'smooth', block: 'center' } );
  };

  const scroll3 = () => {
    const section = document.querySelector( '#section-3' );
    section.scrollIntoView( { behavior: 'smooth', block: 'center' } );
  };

  const scroll4 = () => {
    const section = document.querySelector( '#section-4' );
    section.scrollIntoView( { behavior: 'smooth', block: 'center' } );
  };


  return (
    <div>

    <div className="process-section1-body">
       <motion.div className="process-section1-sub" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, y:0},
              hidden: { opacity: 0, y:50}
            }} >
       How We Works
        </motion.div>

        <motion.div className="process-section1-caption" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, y:0},
              hidden: { opacity: 0, y:50}
            }}>
        The manpower that has been hired from the outsourcing company will help work on the client's project by providing a dedicated team of skilled professionals who will be fully dedicated to the client's project.
        </motion.div>


        {/* <motion.div className="process-section1-tabs" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, },
              hidden: { opacity: 0,}
            }}>
          <div className="process-tab-place">
          <ul className="process-tab">
            <Section1NavItem title={
          <div className="process-section-nav" >
            <AiOutlineFundProjectionScreen className="process-title-icon" />
            <div className="process-section-navtitle">Application</div>
          </div>
        }
            id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}  />

            <Section1NavItem title={
          <div className="process-section-nav">
            <TbConfetti className="process-title-icon" />
            <div className="process-section-navtitle">Resume Screening</div>
          </div>
        }
            id="tab2" activeTab={activeTab} setActiveTab={setActiveTab} />

            <Section1NavItem title={
          <div className="process-section-nav">
            <BiCode className="process-title-icon" />
            <div className="process-section-navtitle">Test & Interview</div>
          </div>
        }
            id="tab3" activeTab={activeTab} setActiveTab={setActiveTab} />



          <Section1NavItem title={
          <div className="process-section-nav">
            <GoRocket className="process-title-icon" />
            <div className="process-section-navtitle">Onboarding</div>
          </div>
        }
            id="tab5" activeTab={activeTab} setActiveTab={setActiveTab} />


          </ul>
        </div>

        <Section1Content id="tab1" activeTab={activeTab}>
        <ProcessContent1 />

        </Section1Content>

        <Section1Content id="tab2" activeTab={activeTab}>
        <ProcessContent2 />
        </Section1Content>

        <Section1Content id="tab3" activeTab={activeTab}>
        <ProcessContent3 />
        </Section1Content>

        <Section1Content id="tab5" activeTab={activeTab}>
        <ProcessContent5 />
        </Section1Content>

        </motion.div> */}
    </div>


      <div className="section-nav-side-body">

        <Row>
         <Col xs={3} sm={3}>
            <div className="section-nav-side">
              <ul className="section-sidenav">
                  <Section1NavItem id="tab1" title={<AiOutlineFundProjectionScreen onClick={scroll1} />} activeTab={activeTab} setActiveTab={setActiveTab} />
                  <Section1NavItem id="tab2" title={<TbConfetti onClick={scroll2} />} activeTab={activeTab} setActiveTab={setActiveTab} />
                  <Section1NavItem id="tab3" title={<BiCode onClick={scroll3} />} activeTab={activeTab} setActiveTab={setActiveTab} />
                  <Section1NavItem id="tab4" title={<GoRocket onClick={scroll4} />} activeTab={activeTab} setActiveTab={setActiveTab} />
              </ul>
          </div>
          </Col>
          <Col xs={9} sm={9}>
          {/* <Section1Content id="tab1" activeTab={activeTab}> */}
           <div id="section-1"><ProcessContent1 /></div>
          {/* </Section1Content> */}

          {/* <Section1Content id="tab2" activeTab={activeTab}> */}
            <div id="section-2"><ProcessContent2 /></div>
          {/* </Section1Content> */}

          {/* <Section1Content id="tab3" activeTab={activeTab}> */}
            <div id="section-3"><ProcessContent3 /></div>            
          {/* </Section1Content> */}

          {/* <Section1Content id="tab4" activeTab={activeTab}> */}
            <div id="section-4"><ProcessContent5  /></div>            
          {/* </Section1Content> */}
          </Col>
          </Row>





          </div>


    </div>
  )
}

export default Section1
