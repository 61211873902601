import React from 'react';
import './Services.css';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

/*Imports */
import Header from '../../Components/Navbar/Header';
import AboutSection2 from '../About/Components/Section2/Section2';
import AboutSection3 from '../About/Components/Section3/Section3';
import Footer from '../../Components/Footer/Footer';
import {motion} from 'framer-motion';


function Services() {

  const variants = {
    visible: { opacity: 1, transition:{duration: 0.7, delay: 1} },
    hidden: { opacity: 0,  },
  };

  const variants1 = {
    visible: { opacity: 1, x:0, transition:{duration: 0.7, delay: 1} },
    hidden: { opacity: 0, x:-50  },
  };

  const variant2 = {
    visible: { opacity: 1, x:0, transition:{duration: 0.7, delay: 1} },
    hidden: { opacity: 0, x:50 },
  };





  return (
    <div>
      <Header />
        <div className="services-hero">
          <motion.div className="services-hero-title"
          initial="hidden" animate="visible" variants={variants}
          >Our Services
          <hr className="home-section1-line"></hr>
          </motion.div>
        </div>
        {/* <div className="home-hero-bottom">
        <motion.div className="home-bottom-title" initial="hidden" animate="visible" variants={variants1}>Get a Free Consultation for Your Case</motion.div>
        <motion.div className="home-bottom-button" initial="hidden" animate="visible" variants={variant2}><Button className="home-contact" as={Link} to="/contact">Contact Us</Button></motion.div>
      </div> */}

      <AboutSection2 />
      <AboutSection3 />
      <Footer />
    </div>
  )
}

export default Services
