import React, {useState} from 'react';
import './Contact.css';
import Header from '../../Components/Navbar/Header';
import {Row, Col, Form, Button} from 'react-bootstrap';
import Footer from '../../Components/Footer/Footer';
import {BsLinkedin} from 'react-icons/bs';
import {motion} from 'framer-motion';
import axios from 'axios';

function Contact() {
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  })

  function submit(e){
    e.preventDefault();
    axios.post('https://api.mobiusdigital.co.id/api/contact-form/submit',
      {
        'firstname': data.firstname,
        'lastname': data.lastname,
        'email': data.email,
        'phone': data.phone,
        'subject': data.subject,
        'message': data.message,
        })
        .then(res => {
          console.log(res.data)
        })

        .then(res => {
          alert('form submitted');
        })

      }

  function handle(e){
    const newdata={...data}
     newdata[e.target.id] = e.target.value
     setData(newdata);

  }


  return (
    <div>
      <Header />

      <div className="contact-body">
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="contact-us-body">
          <motion.div className="contact-title"
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1, x:0},
            hidden: { opacity: 0, x:-50}
          }}
          >
            Opening Hours</motion.div>

          <motion.div className="contact-exp"
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1, x:0},
            hidden: { opacity: 0, x:-50}
          }}
          >
            Daily:
            <span className="contact-desc"> 9.00 AM - 6.00 PM</span>
          </motion.div>

          <motion.div className="contact-exp"
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1, x:0},
            hidden: { opacity: 0, x:-50}
          }}
          >
          Sunday & Holiday:
            <span className="contact-desc"> Closed</span>
          </motion.div>

          <motion.div className="contact-title"
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1, x:0},
            hidden: { opacity: 0, x:-50}
          }}
          >
            Contact Info</motion.div>

          <motion.div className="contact-exp" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }}>
            The Mansion Bougenville Kemayoran<br/>
            Tower Fontana Fl. 50 D1<br/>
            Pademangan, Jakarta Utara<br/>
            DKI Jakarta - Indonesia<br/>
            14410<br/>
          </motion.div>

          <motion.div className="contact-email" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }}>
           <a href="mailto:admin@mobiusdigital.co.id" className="text-decoration-number">admin@mobiusdigital.co.id</a>
          </motion.div>

          <motion.div className="contact-exp"
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1, x:0},
            hidden: { opacity: 0, x:-50}
          }}
          >
          <a href="https://wa.me/62881510078373" className="text-decoration-number">+62 81510078373</a>
          </motion.div>

          <motion.div className="contact-title"
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1, x:0},
            hidden: { opacity: 0, x:-50}
          }}

          >Social Media</motion.div>
          <motion.div className="social-icon-place"
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1, x:0},
            hidden: { opacity: 0, x:-50}
          }}
          >
            <a href="https://www.linkedin.com/company/mobius-world/">
            <BsLinkedin className="social-media-icon"/>
            </a>
          </motion.div>

          </div>

          </Col>

          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
          <motion.div className="contact-us" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:50}
            }}>
                  <span className="contact-us-title">Hi, How can we help you?</span>
                    <Form onSubmit={(e)=> submit(e)}>
                      <Form.Group>
                       <Row className="form-group">
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Form.Control type="text" onChange={(e) => handle(e)} name="firstname" id="firstname" value={data.firstname} placeholder="First Name" className="form-small" />
                          
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Form.Control type="text" onChange={(e) => handle(e)} name="lastname" id="lastname" value={data.lastname} placeholder="Last Name" className="form-small" />
                          
                          </Col>
                        </Row>

                        <Row className="form-padding">
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Form.Control type="email" onChange={(e) => handle(e)} name="email" id="email" value={data.email} placeholder="Email" className="form-small" />
                          <span className="red">*required</span>
                           </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Form.Control type="tel" onChange={(e) => handle(e)} name="phone" id="phone" value={data.phone}  placeholder="Phone Number" className="form-small" />
                          &nbsp; <span className="red">At least 7 character</span>
                          </Col>
                        </Row>
                        <Row className="form-padding">
                          <Col xs={12}>
                          <Form.Control type="text" onChange={(e) => handle(e)} name="subject" id="subject" value={data.subject} placeholder="Subject" className="form-small" />
                          <span className="red">*required</span>
                          </Col>
                        </Row>
                        <div className="form-padding">
                        <Form.Control as="textarea" rows={3} onChange={(e) => handle(e)} name="message" id="message" value={data.message} placeholder="Message" className="form-small" />
                        &nbsp; <span className="red">*required</span>
                        </div>
                      </Form.Group>




                      <Button variant="primary" type="submit" className="form-button">
                        Send Message
                      </Button>
                    </Form>
            </motion.div>
          </Col>


        </Row>


      </div>

      <Footer />

    </div>
  )
}

export default Contact
