import React, {useState} from 'react';
import './Section2.css';
import{Row, Col} from 'react-bootstrap';

import Section2Content from './Section2Content';
import Section2NavItem from './Section2NavItem';
import {motion} from 'framer-motion';

function Section2() {
    const [activeTab, setActiveTab] = useState("tab1");
  return (
    <div className="home-section2-body">
    <Row className="section-row g-0">
        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
        <motion.div className="section2-img-place" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1,},
              hidden: { opacity: 0, }
            }}>
        </motion.div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
        <div className="home-section2-section">
        <motion.div className="home-section2-sub"
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1, x:0},
          hidden: { opacity: 0, x:50}
        }}
        >

          EXPERIENCES</motion.div>
        <motion.div className="home-section2-title"
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:50}
            }}>
          Let Our Experience be Your Guide</motion.div>
        <motion.div className="section2" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, },
              hidden: { opacity: 0,}
            }}>
          <div className="section-nav">
        <Section2NavItem title="About Us" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}  /> </div>
       <div className="section-nav"> <Section2NavItem title="Our Focus" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab} /> </div>
        <div className="section-nav"><Section2NavItem title="Our Expertise" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab} /></div>

        </motion.div>

        <Section2Content id="tab1"  activeTab={activeTab} >
        <motion.div className="section2-content"
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1, y:0},
          hidden: { opacity: 0, y:5}
        }}>


        Mobius is an IT consulting & outsourcing company. We provide services to any business with wide-range from small to global scale by providing IT professional talents and agile-ready teams to support our customers.
        </motion.div>
        </Section2Content>

        <Section2Content id="tab2" activeTab={activeTab}>
        <motion.div className="section2-content" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, y:0},
              hidden: { opacity: 0, y:5}
            }}>
        We listen to what you want to achieve. Then we make recommendations before designing, implementing and optimizing your business requirements.
        </motion.div>
        </Section2Content>

        <Section2Content id="tab3" activeTab={activeTab}>
        <motion.div className="section2-content"initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, y:0},
              hidden: { opacity: 0, y:5}
            }}>
        We are building a character of reliable and skillful talents through educating young individuals in honing their skills and becoming professionals to meet the up-to-date technology demands in certain respective industries.
        </motion.div>
        </Section2Content>




        </div>
        </Col>
    </Row>
    </div>
  )
}

export default Section2
