import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './BlogDetails.css';
import Header from '../../Components/Navbar/Header';
import AboutSection3 from '../About/Components/Section3/Section3';
import Footer from '../../Components/Footer/Footer';
import {useParams} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import {motion} from 'framer-motion';



function BlogDetails(props) {
  let {id} = useParams();

  const [post, setPost] = useState([]);


  useEffect(() => {
    axios.get(`https://api.mobiusdigital.co.id/api/article/${id}`)
    .then(res => {
        console.log(res)
        setPost(res.data.data[0])
    })
    .catch(err =>{
        console.log(err)
    })
    },[])

    const variants = {
      visible: { opacity: 1, transition:{duration: 0.7, delay: 1} },
      hidden: { opacity: 0,  },
    };


  return (
    <div>
      <Header />
      <div className="blog-hero">
      <motion.div className="blog-title" initial="hidden" animate="visible" variants={variants}>Blog
        <hr className="home-section1-line"></hr>
        </motion.div>
      </div>

      <div className="blog-details-background">

          <div className="blog-details-body" key={post.id}>
            <motion.div className="blog-details-img-place"
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, y:0},
              hidden: { opacity: 0, y:50}
            }}
            >
            <img className="blog-details-img" src={`https://api.mobiusdigital.co.id${post.thumbnail_path}`} alt="" />
            </motion.div>
            <div className="blog-details-text-place">
              <div className="blog-details-author">By. {post.author}</div>
              <motion.div className="blog-details-text" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }}>
              <ReactMarkdown rehypePlugins={[rehypeRaw]} >{post.content}</ReactMarkdown>
              </motion.div>


            <div className="tags-title">Tags</div>
            <div className="tag-body-place">
                {post.tag?.map(post =>
                  <div className="tag-body">
                    {post}
                  </div>
                  )}
              </div>


            </div>

          </div>
      </div>

      <AboutSection3 />
      <Footer />

    </div>
  )
}

export default BlogDetails
