import React from 'react';
import './Section1.css';
import {Row, Col} from 'react-bootstrap';
import {TbConfetti} from 'react-icons/tb';
import {IoCheckmarkCircleOutline} from 'react-icons/io5';
import {motion} from 'framer-motion';

function ProcessContent2() {
  return (
    <motion.div className="process-tab-content" initial="hidden"
    whileInView="visible"
    transition={{ duration: 0.8 }}
    variants={{
      visible: { opacity: 1, y:0},
      hidden: { opacity: 0, y:5}
    }}>
      <Row noGutters={true}>
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
        <div className="process-tabcontent-title">
        Resume Screening
        </div>

        <div className="process-tabcontent-text">
        This stage is an advanced stage where the customer can conduct interviews with talents.
        </div>

        <div className="display">
          <IoCheckmarkCircleOutline className="value-icon" />
          <div className="tab-value"> User Interview</div>
        </div>
        
        <div className="display">
          <IoCheckmarkCircleOutline className="value-icon"/> 
         <div className="tab-value">Approving and Signing Administrative Documents</div><br></br>
        </div>

        <div className="display">
        <IoCheckmarkCircleOutline className="value-icon" />
         <div className="tab-value"> Preparing the prerequisites</div>

        </div>

        
        </Col>


        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
        <div className="process-tab-icon-place">
        <TbConfetti className="process-tab-icon" />
        </div>
        </Col>

      </Row>

    </motion.div>
  )
}

export default ProcessContent2
