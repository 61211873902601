import React from 'react';
import './Section3.css';
import {Row, Col} from 'react-bootstrap';
import {motion} from 'framer-motion';
import CountUp from 'react-countup';

function Section3() {
  return (
    <div className="home-section3-body">
        <Row>
            <Col xs={12} sm={12} lg={4}>
                <motion.div className="home-section3-sub"
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 0.8 }}
                variants={{
                  visible: { opacity: 1, x:0},
                  hidden: { opacity: 0, x:-50}
                }}
                >WELCOME</motion.div>

                <motion.div className="home-section3-title"
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 0.8 }}
                variants={{
                  visible: { opacity: 1, x:0},
                  hidden: { opacity: 0, x:-50}
                }}
                >
                What We Did?
                <hr className="home-section1-line"></hr>
                </motion.div>

                <motion.div className="home-section3-text"
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 0.8 }}
                variants={{
                  visible: { opacity: 1},
                  hidden: { opacity: 0}
                }}>
                We are proud to celebrate our achievements so far
                </motion.div>
            </Col>
            <Col xs={12} sm={12} lg={8} className="margin-top-section3">
                <Row>
                <Col xs={12} sm={4}>
                    <div className="home-section3-section">
                    <div className="home-section3-number">
                    <CountUp end={150} duration={5} enableScrollSpy={true} />+
                    </div>
                    Professional Tech Talents
                    </div>
                </Col>

                <Col xs={12} sm={4}>
                    <div className="home-section3-section">
                    <div className="home-section3-number">
                    <CountUp end={50} duration={5} enableScrollSpy={true} />+
                    </div>
                    Succesful Projects
                    </div>
                </Col>

                <Col xs={12} sm={4}>
                    <div className="home-section3-section">
                    <div className="home-section3-number">
                    <CountUp end={20} duration={5} enableScrollSpy={true} />+
                    </div>
                    Satisfied Clients
                    </div>
                </Col>


                </Row>




            </Col>
        </Row>

    </div>
  )
}

export default Section3
