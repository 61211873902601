import React from 'react';
import './Home.css';
import Navigation from '../../Components/Navbar/Navigation';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { motion} from 'framer-motion';

/*Import Sections*/
import Section1 from './Components/Section1/Section1';
import Section3 from './Components/Section3/Section3';
import Client from './Components/Client/Client';
import Footer from '../../Components/Footer/Footer';
import AboutSection3 from '../About/Components/Section3/Section3';
import Typewriter from "typewriter-effect";
import ClientBlack from '../../Components/ClientBlack/ClientBlack';



function Home() {
  const variants = {
    visible: { opacity: 1, transition:{duration: 0.7, delay: 1} },
    hidden: { opacity: 0,  },
  };



  return (
    <div>
      <Navigation />
      <div className="home-hero">
        <div className="home-title">
        <Typewriter

          onInit={(typewriter)=> {

          typewriter

          .typeString("Empowering your business through technology")
          .start();
          }}
          />

        </div>
        <motion.div className="home-text" initial="hidden" animate="visible" variants={variants}>
        Mobius helps you provide quality, best-in-nation tech talent to support your business. together with you, we turn ideas into digitalized reality.
        </motion.div>
      </div>

      {/* <div className="home-hero-bottom">
        <motion.div className="home-bottom-title" initial="hidden" animate="visible" variants={variants}>Get a Free Consultation for Your Case</motion.div>
        <motion.div className="home-bottom-button" initial="hidden" animate="visible" variants={variants}>
          <Button className="home-contact" as={Link} to="/contact">Contact Us</Button>
          </motion.div>
      </div> */}

      <Section1 />
      <Section3 />
      <ClientBlack />
      <Client />
      <AboutSection3 />


      <Footer />


    </div>
  )
}

export default Home
