import React from 'react';
import './Process.css';

/*Imports */

import Header from '../../Components/Navbar/Header';
import Section1 from './Components/Section1/Section1';

import AboutSection3 from '../About/Components/Section3/Section3';
import Footer from '../../Components/Footer/Footer';
import {motion} from 'framer-motion';

function Process() {
  const variants = {
    visible: { opacity: 1, transition:{duration: 0.7, delay: 1} },
    hidden: { opacity: 0,  },
  };

  return (
    <div>
      <Header />
      <div className="process-hero">
        <motion.div className="process-title" initial="hidden" animate="visible" variants={variants}>Process
        <hr className="home-section1-line"></hr>
        </motion.div>
      </div>

      <Section1 />
      <AboutSection3 />
      <Footer />


    </div>
  )
}

export default Process
