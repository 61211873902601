import React from 'react';
import '../ServicesDetails.css';
import Header from '../../../Components/Navbar/Header';
import {Row, Col} from 'react-bootstrap';
import Section2 from '../Section2';
import AboutSection3 from '../../About/Components/Section3/Section3';
import Footer from '../../../Components/Footer/Footer';
import {motion} from 'framer-motion';
import {IoCheckmarkCircleOutline} from 'react-icons/io5';


function ServicesDetail3() {
  const variants = {
    visible: { opacity: 1, transition:{duration: 0.7, delay: 1} },
    hidden: { opacity: 0,  },
  };

  return (
    <div>
        <Header />
        <div className="servicesdetails-hero">
          <motion.div className="servicesdetails-hero-title"
          initial="hidden" animate="visible" variants={variants}
          >Our Services
          <hr className="home-section1-line"></hr>
          </motion.div>
        </div>

        <div className="process-section2-body">
        <Row>
            <Col xs={12} sm={12} md={12} lg={6}>
            <motion.div className="process-section2-img-place3"
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }}
            >

            </motion.div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
            <motion.div className="process-section2-content"
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:50}
            }}
            >
                <div className="process-section2-title">
                SaaS Development                                </div>
                <div className="process-section2-text">
                With SaaS development service from Mobius, your business can enjoy the benefit of a flexible, effective, and efficient software development solution that is accessible and deployable anywhere and anytime. 
              </div>

              <div className="display">
                    <IoCheckmarkCircleOutline className="value-icon" />
                    <div className="tab-value color-gray">Scalability: Our SaaS development service allows your business to scale up as needed while staying flexible and cost-effective.
                    </div>
                  </div>
                  
                  <div className="display">
                    <IoCheckmarkCircleOutline className="value-icon"/> 
                  <div className="tab-value color-gray">Continuous updates: Our SaaS development service includes regular updates and maintenance, ensuring your software is always up to date and running smoothly. </div><br></br>
                  </div>

                  <div className="display">
                  <IoCheckmarkCircleOutline className="value-icon" />
                  <div className="tab-value color-gray">Continuous updates: Our SaaS development service includes regular updates and maintenance, ensuring your software is always up to date and running smoothly.</div>

                  </div>

            </motion.div>

            </Col>

        </Row>
    </div>

    <Section2 />
    <AboutSection3 />
    <Footer />




    </div>
  )
}

export default ServicesDetail3
