import React from 'react';
import './Section2.css';
import {Row, Col, Card} from 'react-bootstrap';

import SaaSIcon from '../../../../assets/saasicon.png';
import PersonIcon from '../../../../assets/personicon.png';
import TeamIcon from '../../../../assets/teamicon.png';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';


function Section2() {
  return (
    <div className="about-section2-body">
        <Row>
            <Col  xs={12} sm={12} md={6} lg={4}>
                <motion.div initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }} >
                <Card className="about-section2-cards" as={Link} to="/services1">
                <Card.Body>
                        <img src={PersonIcon} alt="" className="about-cards-icon" />
                        <div className="about-cards-title">
                        Mobius Tech Professional as a Person 
                        </div>
                        <div className="about-cards-text">
                        Mobius allows you to personally hire qualified tech professionals.                        </div>
                </Card.Body>
                </Card>
                </motion.div>
              
            </Col>
            <Col xs={12} sm={12} md={6} lg={4}>
                <motion.div initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, y:0},
              hidden: { opacity: 0, y:50}
            }}>
                <Card className="about-section2-cards" as={Link} to="/services2">
                <Card.Body>
                        <img src={TeamIcon} alt="" className="about-cards-icon" />
                        <div className="about-cards-title">
                        Mobius Tech Professional as a Team
                        </div>
                        <div className="about-cards-text">
                        Mobius allows you to assemble the best team according to your needs.                        </div>
                </Card.Body>
                </Card>
            </motion.div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4}>
            <motion.div initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:50}
            }}>
                <Card className="about-section2-cards" as={Link} to="/services3">
                <Card.Body>
                        <img src={SaaSIcon} alt="" className="about-cards-icon" />
                        <div className="about-cards-title">
                        SaaS Development
                        </div>
                        <div className="about-cards-text">
                        Mobius provides enterprise-class applications that give your business a competitive advantage.                        </div>
                </Card.Body>
                </Card>
            </motion.div>
            </Col>
        </Row>



    </div>
  )
}

export default Section2
