import React from 'react';
import './Cards.css';
import {Row, Col, Card, Button } from 'react-bootstrap';
import Card_1 from '../../../../assets/card_1.jpeg';
import Card_2 from '../../../../assets/card_2.jpeg';
import Card_3 from '../../../../assets/card_3.jpeg';
import PersonIcon from '../../../../assets/personicon.png';
import TeamIcon from '../../../../assets/teamicon.png';
import SaasIcon from '../../../../assets/saasicon.png';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';


function CardsSection() {

  return (
    <div className="tab-cards-position" >
      <Row className="g-0">
        <Col className="tab-cards-align g-0" xs={12} sm={12} md={6} lg={4} xl={4}>
          <motion.div className="tab-card"  initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }}>
            <Card >
            <Card.Img src={Card_1} alt="Card image" className="card-image" />
            <Card.ImgOverlay>
              <Card.Text className="card-overlay-tab">
                <div className="text-placement-card">
                <div className="overlay-title">
                <img src={PersonIcon} alt="" className="icon-card-overlay"/>
                Mobius Tech Professional as a Person 
                </div>
                <div className="overlay-text">
                Equipping digital talent with proven skills is a challenge in rapidly changing technology disruption...
                </div>
                </div>

                <Button className="overlay-button" as={Link} to="/services1">Read More</Button>

              </Card.Text>

              <div className="card-bottom">
                <img src={PersonIcon} alt="" className="icon-card-bottom" />
                Mobius Tech Professional as a Person </div>
            </Card.ImgOverlay>
              </Card>
              </motion.div>
        </Col>

         <Col className="tab-cards-align g-0" xs={12} sm={12} md={6} lg={4} l={4}>
         <motion.div className="tab-card"  initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, y:0},
              hidden: { opacity: 0, y:50}
            }}>
              <Card>
            <Card.Img src={Card_2} alt="Card image" className="card-image" />
            <Card.ImgOverlay>
              <Card.Text className="card-overlay-tab">
              <div className="text-placement-card">
                <div className="overlay-title">
                <img src={TeamIcon} alt="" className="icon-card-overlay" />
                Mobius Tech Professional as a Team
                </div>
                <div className="overlay-text">
                The team has two roles that support the company. Some teams are tasked with completing the development of...
                 </div>
                </div>
                <Button className="overlay-button" as={Link} to="/services2">Read More</Button>
              </Card.Text>

              <div className="card-bottom">
                <img src={TeamIcon} alt="" className="icon-card-bottom-2" />
                Mobius Tech Professional as a Team</div>
            </Card.ImgOverlay>
              </Card>
              </motion.div>
        </Col>
        <Col className="tab-cards-align g-0" xs={12} sm={12} md={6} lg={4} l={4}>
        <motion.div className="tab-card"  initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:50}
            }}>
              <Card>
            <Card.Img src={Card_3} alt="Card image" className="card-image" />
            <Card.ImgOverlay>
              <Card.Text className="card-overlay-tab">
              <div className="text-placement-card">
                <div className="overlay-title">
                <img src={SaasIcon} alt="" className="icon-card-overlay" />
                SaaS Development
                </div>
                <div className="overlay-text">
                75% of companies stated they reach accelerated business agility after migrating to SaaS. Mobius provides...
                </div>
                </div>
                <Button className="overlay-button" as={Link} to="/services3">Read More</Button>
              </Card.Text>

              <div className="card-bottom">
                <img src={SaasIcon} alt="" className="icon-card-bottom-2" />
                SaaS Development</div>
            </Card.ImgOverlay>
              </Card>
              </motion.div>
        </Col>
      </Row>

    </div>
  )
}

export default CardsSection
