import React, {useState, useEffect} from 'react';
import{useParams} from 'react-router-dom';
import axios from 'axios';
import Header from '../../Components/Navbar/Header';
import {Link} from 'react-router-dom';
import Footer from '../../Components/Footer/Footer';
import Client from '../Home/Components/Client/Client';
import {Row, Col, ProgressBar} from 'react-bootstrap';
import './Details.css';
import {motion} from 'framer-motion';
import {HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight} from 'react-icons/hi';
import CountUp from 'react-countup';


function PortfolioDetails() {
  let {id} = useParams();

  const [post, setPost] = useState([]);

  useEffect(() => {
    axios.get(`https://api.mobiusdigital.co.id/api/portfolio/${id}`)
    .then(res => {
        console.log(res)
        setPost(res.data.data[0])
    })
    .catch(err =>{
        console.log(err)
    })
    },[])

    const variants = {
      visible: { opacity: 1, y:0, transition:{duration: 0.7, delay: 1} },
      hidden: { opacity: 0, y: 50 },
    };









  return (
    <div >
      <Header />
      <div className="portfolio-details-hero" key={post.id} >
        <motion.div initial="hidden" animate="visible" variants={variants}>
        <Link to="/" className="text-decoration portfolio-details-link">Home /</Link>  &nbsp;
        <Link to="/portfolio" className="text-decoration portfolio-details-link">Our Portfolio /</Link> &nbsp;
        <span className="link-title">{post.client}</span>

        <div className="portfoliodetails-title">{post.client}</div>
    </motion.div>
      </div>

      <div className="portfoliodetails-section1">
        <Row>
          <Col xs={12} lg={6}>
              <motion.div className="portodetails-section1-body" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }} >
                {post.client}</motion.div>
              <motion.div className="portodetails-section1-industry" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }} >
                {post.industry}</motion.div>
              <motion.div className="portodetails-section1-text" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }} >
                {post.client_description}</motion.div>

              <motion.div className="portodetails-section1-body"
              initial="hidden"
              whileInView="visible"
              transition={{ duration: 0.8 }}
              variants={{
                visible: { opacity: 1, x:0},
                hidden: { opacity: 0, x:-50}
              }}>Value Deliverables</motion.div>

              <motion.div className="deliv" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }}>
              {post.deliverables?.map(post =>
              <li key={post}>{post}</li>
                )}

                </motion.div>
          </Col>

          <Col xs={12} lg={6}>
            <motion.div className="client-logo-place" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:50}
            }}>
          <img src={`https://api.mobiusdigital.co.id${post.client_logo}`} className="client-logo" alt="" />
          </motion.div>
          </Col>
        </Row>
      </div>

      {/* <div className="portfoliodetails-section2">
        <Row>
          <Col xs={12} lg={6}>
            <motion.div className="portodetails-section2-title" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }}>Complexity:</motion.div>
            <motion.div className="circle" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }}>{post.complexity}%</motion.div>
          </Col>

          <Col xs={12} lg={6}>
              <motion.div className="project-summary-title"
              initial="hidden"
              whileInView="visible"
              transition={{ duration: 0.8 }}
              variants={{
                visible: { opacity: 1, x:0},
                hidden: { opacity: 0, x:50}
              }}
              >
                Project Summary</motion.div>
              <motion.div className="project-summary-text" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:50}
            }}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]} >{post.project_summary}</ReactMarkdown>
              </motion.div>

              <motion.div className="details-platform" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:50}
            }}>
                Platform : &nbsp;

                {post.platforms?.map( post=>
                  <div className="platform-place" key={post.name}>
                    <img src={`https://api.mobiusdigital.co.id${post.image_path}`} className="platform-image" alt="" />
                  </div>)}
              </motion.div>

          </Col>
        </Row>
      </div> */}

      <div className="banner-tech-stack">

        <Row className="g-o">
          <Col xs={12} sm={12} md={6}>
             <motion.div className="title-margin-porto"
             initial="hidden"
             whileInView="visible"
             transition={{ duration: 0.8 }}
             variants={{
               visible: { opacity: 1, x:0},
               hidden: { opacity: 0, x:-50}
             }} 
             >Tech Talent Deployed</motion.div> 
              <motion.div className="position-details"
              initial="hidden"
              whileInView="visible"
              transition={{ duration: 0.8 }}
              variants={{
                visible: { opacity: 1, x:0},
                hidden: { opacity: 0, x:-50}
              }} 
              >
                <CountUp end={`${post.personnels}`} duration={3} enableScrollSpy={true} />
              </motion.div>
          </Col>
          <Col xs={12} sm={12} md={6}>
          <motion.div className="title-margin-porto"
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1, x:0},
            hidden: { opacity: 0, x:50}
          }} 
          > Main Position</motion.div>
              <motion.div className="position-details"
              initial="hidden"
              whileInView="visible"
              transition={{ duration: 0.8 }}
              variants={{
                visible: { opacity: 1, x:0},
                hidden: { opacity: 0, x:50}
              }} 
              >
                {post.role}
              </motion.div>
          </Col>
        </Row>

        <Row className="margin-tech-stack">
          <Col xs={12} sm={12} md={6}>
              <motion.div className="title-margin-porto"
              initial="hidden"
              whileInView="visible"
              transition={{ duration: 0.8 }}
              variants={{
                visible: { opacity: 1, x:0},
                hidden: { opacity: 0, x:-50}
              }} 
              >Overall Seniority Level</motion.div>
              <motion.div className="margin-top-progress"
              initial="hidden"
              whileInView="visible"
              transition={{ duration: 0.8 }}
              variants={{
                visible: { opacity: 1, x:0},
                hidden: { opacity: 0, x:-50}
              }} 
              >
                <div className="progress-progress">
                <div className="progress-bar">
                  <div className="junior">Junior</div>
                  <div className="senior">Senior</div>
                 
                  </div>
                  <div className="position-progress">
                   <ProgressBar 
                  now={`${post.complexity}`}/>
                  </div>
                  </div>
              
              </motion.div>
          </Col>
          <Col xs={12} sm={12} md={6}>
          <motion.div className="title-margin-porto"
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1, x:0},
            hidden: { opacity: 0, x:50}
          }} 
          >Project Duration</motion.div>
          <motion.div className="position-details"
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1, x:0},
            hidden: { opacity: 0, x:50}
          }} 
          >
            {post.duration} Years
          </motion.div>
          </Col>
        </Row>

       
      </div>

        <div className="control-button-place">
            
            <div className="control-button-prev">
              <Link to={`/portfoliodetails/${post.prev?.prev_id}`} value="Reload Page" onClick="document.location.reload(true)"  className="text-decoration">
              <Row className="g-0">
                <Col xs={3} sm={3}>
                  <div className="button-placement-control-left">
                    <HiOutlineArrowNarrowLeft className="arrow-color" /></div>
                </Col>

              <Col xs={8} sm={8}>
                 <div className="control-button-title">Previous Project</div>
                <div className="client-control-name">{post.prev?.prev_client}</div>

              </Col>

            </Row>
              </Link>
            </div>

            
            <div className="control-button-next">
              <Link to={`/portfoliodetails/${post.next?.next_id}`} value="Reload Page" onClick="document.location.reload(true)"  className="text-decoration">
              <Row className="g-0">
                <Col xs={8} sm={8}>
                <div className="control-button-title button-margin">Next Project </div>
                <div className="client-control-name button-margin">{post.next?.next_client}</div>
                </Col>
                <Col xs={4} sm={4}>
                  <div className="button-placement-control-right">
                    <HiOutlineArrowNarrowRight className="arrow-color" /></div>
                </Col>

              </Row>
            </Link>

            </div>

        </div>



          <Client />
          <Footer />

    </div>
  )
}

export default PortfolioDetails
