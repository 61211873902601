import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './Section1.css';
import {Card, Button } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';



function Section1() {
  const [posts, setPosts] = useState([]);
  const [visible, setVisible] = useState(3);

  const loadMore = () => {
    setVisible(visible + 3)
  }

  useEffect(() => {
      axios.get('https://api.mobiusdigital.co.id/api/portfolios')
      .then(res => {
          console.log(res)
          setPosts(res.data.data)
      })
      .catch(err =>{
          console.log(err)
      })
  },[])

  const variants = {
    visible: { opacity: 1, transition:{duration: 0.7, delay: 1} },
    hidden: { opacity: 0,  },
  };


  return (
    <div className="portfolio-section1-body">
      <motion.div id="grid-1" initial="hidden" animate="visible" variants={variants}>
                {posts.slice(0, visible).map(post => (

                    <Card className="portfolio-posts" key={post.id}>
                        <Link to= {`/portfoliodetails/${post.id}`} className="text-decoration">
                          <div className="client-logo-card-place">
                            <img alt="" className="client-logo-img" src={`https://api.mobiusdigital.co.id${post.client_logo}`} />

                          </div>
                      <div className="portfolio-image-place">
                      <Card.Img className="portfolio-img-top" src={`https://api.mobiusdigital.co.id${post.thumbnail_path}`}/>
                      </div>
                      <div className="portfolio-texts-place">
                          <div className="portfolio-post-title">
                            {post.client}
                            </div>
                          <div className="portfolio-post-text">
                            {post.client_description}
                            </div>
                      </div>
                      </Link>
                    </Card>

                ))}
          </motion.div>
          <Button onClick={loadMore} className="section-btn-load-more">Load More</Button>

    </div>
  )
}

export default Section1
