import React from 'react';
import './Career.css';
import Header from '../../Components/Navbar/Header';
import Section1 from './Components/Section1/Section1';
import Footer from '../../Components/Footer/Footer';
import {motion} from 'framer-motion';

function Career() {

  return (
    <div>
      <Header />
      <div className="career-hero">
        <motion.div className="career-hero-sub"
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1, x:0},
          hidden: { opacity: 0, x:-50}
        }}
        >Join Our Company</motion.div>
        <motion.div className="career-hero-title" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:50}
            }} >
          Unlock your full potential and advance your career with Mobius!</motion.div>
        <motion.div className="career-hero-exp" initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }} >Join the team and take your career to a new height! Our team is dedicated to helping you develop your skills and reach your full potential.
        </motion.div>
      </div>

      <Section1 />
      <Footer />


    </div>
  )
}

export default Career
