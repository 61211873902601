import React from 'react';
import '../ServicesDetails.css';
import Header from '../../../Components/Navbar/Header';
import {Row, Col} from 'react-bootstrap';
import Section2 from '../Section2';
import AboutSection3 from '../../About/Components/Section3/Section3';
import Footer from '../../../Components/Footer/Footer';
import {motion} from 'framer-motion';
import {IoCheckmarkCircleOutline} from 'react-icons/io5';

function ServicesDetails2() {
  const variants = {
    visible: { opacity: 1, transition:{duration: 0.7, delay: 1} },
    hidden: { opacity: 0,  },
  };
  return (
    <div>
        <Header />
        <div className="servicesdetails-hero">
          <motion.div className="servicesdetails-hero-title"
          initial="hidden" animate="visible" variants={variants}
          >Our Services
          <hr className="home-section1-line"></hr>
          </motion.div>
        </div>

        <div className="process-section2-body">
        <Row>
            <Col xs={12} sm={12} md={12} lg={6}>
            <motion.div className="process-section2-img-place2"

              initial="hidden"
              whileInView="visible"
              transition={{ duration: 0.8 }}
              variants={{
                visible: { opacity: 1, x:0},
                hidden: { opacity: 0, x:-50}
              }}
            >

            </motion.div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
            <motion.div className="process-section2-content"
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:50}
            }}
            >
                <div className="process-section2-title">
                Mobius Tech Professional as a Team
                                </div>
                <div className="process-section2-text">
                Mobius tech team may be filled with fun people, but we are also dedicated to collaborating with your internal team to bring your technology to the next level. From product analysis, UI/UX design, development, testing, to the deployment and maintenance stage, we will work together with your team to guarantee the success of the project. We will deliver:
                </div>

                <div className="display">
                    <IoCheckmarkCircleOutline className="value-icon" />
                    <div className="tab-value color-gray">A seamless working digital product, fully utilizing the latest technology following your requirements
                    </div>
                  </div>
                  
                  <div className="display">
                    <IoCheckmarkCircleOutline className="value-icon"/> 
                  <div className="tab-value color-gray">Full access to all deliverables from initiation and concept deck, designs, assets, technical documentation, and all of the code bases</div><br></br>
                  </div>

                  <div className="display">
                  <IoCheckmarkCircleOutline className="value-icon" />
                  <div className="tab-value color-gray">Post-project service cadence that maintains, tracks, evaluates, improves, and reports all the performance metrics to ensure a smooth project handover and transition</div>

                  </div>

            </motion.div>

            </Col>

        </Row>
    </div>

    <Section2 />
    <AboutSection3 />
    <Footer />




    </div>
  )
}

export default ServicesDetails2
