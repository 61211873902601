import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './Section1.css';
import {Row, Col, Card, Button} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import {motion} from 'framer-motion';






function Section1() {
  const [posts, setPosts] = useState([]);
  const [visible, setVisible] = useState(3);

  const loadMore = () => {
    setVisible(visible + 3)
  }

    useEffect(() => {
        axios.get('https://api.mobiusdigital.co.id/api/careers')
        .then(res => {
            console.log(res)
            setPosts(res.data.data)
        })
        .catch(err =>{
            console.log(err)
        })
    },[])

  return (
    <div className="career-card-body">
      <div id="grid">
                {posts.slice(0, visible).map(post => (
                  <motion.div initial="hidden"
                  whileInView="visible"
                  transition={{ duration: 0.8 }}
                  variants={{
                    visible: { opacity: 1, y:0},
                    hidden: { opacity: 0, y:50}
                  }}>
                    <Card className="cards-career" key={post.career} >
                      <Row>
                        <Col xs={10}><div className="card-career-title">{post.career}</div></Col>
                        <Col xs={2}><div className="cards-career-level" style={{backgroundColor: `${post.level_color}`}}> {post.level}</div></Col>
                      </Row>
                      <div>{post.type.type}</div>
                      <hr className="career-line"></hr>
                      <div className="card-career-exp">
                        <ReactMarkdown rehypePlugins={[rehypeRaw]} >{post.detail}</ReactMarkdown>
                      </div>
                      <hr className="career-line"></hr>

                      <a href="mailto:admin@mobiusdigital.tech">
                      <Button className="career-button">Apply on Email</Button></a>
                    </Card>
                  </motion.div>
                ))}
        </div>

        <Button onClick={loadMore} className="section-btn-load-more">Load More</Button>




    </div>
  )
}

export default Section1
