import React from 'react';
import './Section1.css';
import {Row, Col} from 'react-bootstrap';
import {GoRocket} from 'react-icons/go';
import {IoCheckmarkCircleOutline} from 'react-icons/io5';
import {motion} from 'framer-motion';

function ProcessContent5() {
  return (
    <motion.div className="process-tab-content" initial="hidden"
    whileInView="visible"
    transition={{ duration: 0.8 }}
    variants={{
      visible: { opacity: 1, y:0},
      hidden: { opacity: 0, y:5}
    }}>
      <Row noGutters={true}>
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
        <div className="process-tabcontent-title">
        Onboarding
        </div>

        <div className="process-tabcontent-text">
        Our experts are ready to onboard and assist your business needs
        </div>

        <div className="display">
          <IoCheckmarkCircleOutline className="value-icon" />
          <div className="tab-value">Project Briefing</div>
        </div>
        
        <div className="display">
          <IoCheckmarkCircleOutline className="value-icon"/>
          <div className="tab-value">Onboarding as the part of customer’s team</div>
        </div>
         
         <div className="display">
          <IoCheckmarkCircleOutline className="value-icon" />
          <div className="tab-value">Providing best assistance to customer</div>
         </div>
         
        </Col>


        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
        <div className="process-tab-icon-place">
        <GoRocket className="process-tab-icon" />
        </div>
        </Col>

      </Row>

    </motion.div>
  )
}

export default ProcessContent5
