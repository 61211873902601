import React from 'react';
import '../ServicesDetails.css';
import Header from '../../../Components/Navbar/Header';
import {Row, Col} from 'react-bootstrap';
import Section2 from '../Section2';
import AboutSection3 from '../../About/Components/Section3/Section3';
import Footer from '../../../Components/Footer/Footer';
import {motion} from 'framer-motion';
import {IoCheckmarkCircleOutline} from 'react-icons/io5';

function ServicesDetail1() {
  const variants = {
    visible: { opacity: 1, transition:{duration: 0.7} },
    hidden: { opacity: 0,  },
  };
  
  return (
    <div>
        <Header />
        <div className="servicesdetails-hero">
          <motion.div className="servicesdetails-hero-title"
          initial="hidden" animate="visible" variants={variants}
          >Our Services
          <hr className="home-section1-line"></hr>
          </motion.div>
        </div>

        <div className="process-section2-body">
        <Row>
            <Col xs={12} sm={12} md={12} lg={6}>
            <motion.div className="process-section2-img-place1"
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }}
            >

            </motion.div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
            <motion.div className="process-section2-content"
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:50}
            }}
            >
                <div className="process-section2-title">
                Mobius Tech Professional as a Person 
                </div>
                <div className="process-section2-text">
                We are qualified technology professionals in the tech industry. We have an unmatched work ethic and expertise, and you can count on our people to stay up-to-date with the latest tech knowledge. Experience how our people:
                </div>

                <div className="display">
                    <IoCheckmarkCircleOutline className="value-icon" />
                    <div className="tab-value color-gray">Provide you with the best and most highly qualified tech talent in their field.</div>
                  </div>
                  
                  <div className="display">
                    <IoCheckmarkCircleOutline className="value-icon"/> 
                  <div className="tab-value color-gray">Show you advanced and state-of-the-art UI/UX Design, IT Maintenance, Web Development, and many more.</div><br></br>
                  </div>

                  <div className="display">
                  <IoCheckmarkCircleOutline className="value-icon" />
                  <div className="tab-value color-gray">Lead you with innovative and inventive solutions to your problems.</div>

                  </div>


            </motion.div>

            </Col>

        </Row>
    </div>

    <Section2 />
    <AboutSection3 />
    <Footer />




    </div>
  )
}

export default ServicesDetail1
