import React from 'react';
import './About.css';
import Header from '../../Components/Navbar/Header';

/*Imports */
import Section1 from './Components/Section1/Section1';
import HomeSection3 from '../Home/Components/Section3/Section3';
import HomeSection2 from '../Home/Components/Section2/Section2';
import Section2 from './Components/Section2/Section2';
import Section3 from './Components/Section3/Section3';
import Footer from '../../Components/Footer/Footer';
import {motion} from 'framer-motion';

function About() {
  return (
    <div>
      <Header />
      <div className="about-hero">
        <motion.div className="about-title"
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1, y:0},
          hidden: { opacity: 0, y:50}
        }}
        >About Us
        <hr className="home-section1-line"></hr>
        </motion.div>
      </div>

      <Section1 />
      <HomeSection3 />
      <HomeSection2 />
      <Section2 />
      <Section3 />
      <Footer />

    </div>
  )
}

export default About
