import React, {useRef} from 'react';
import './Section1.css';
import {Row, Col} from 'react-bootstrap';
import CardsSection from './CardsSection';
import {motion, useTransform, useScroll} from 'framer-motion';

function Section1() {
  const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start end", "end end"]
    });

    const opacityvalue = useTransform(scrollYProgress,[0, 1], [0,1] )
    const position = useTransform(scrollYProgress,[0, 1], [-70, 0] )
    const position2 = useTransform(scrollYProgress,[0, 1], [70, 0] )


  return (
    <div>
        <div className="home-section1-body" ref={containerRef}>
        <Row>
            <Col sm={4}>
            <motion.div className="home-section1-sub" style={{opacity: opacityvalue, x:position}} >
                WELCOME
            </motion.div>
            <motion.div className="home-section1-title" style={{opacity: opacityvalue, x:position}} >
            Expertise at <br></br>your fingertips
            <hr className="home-section1-line"></hr>
            </motion.div>
            </Col>

            <Col sm={8}>
            <motion.div className="home-section1-text" style={{opacity: opacityvalue, x:position2}}>
            Mobius is an IT consulting & outsourcing company. We help any business with wide-range scale by providing IT professional talents and agile-ready teams to support your business.
            </motion.div>
            </Col>
        </Row>

        </div>

        <div className="home-section1-bodybottom">
          <CardsSection />

        </div>






    </div>
  )
}

export default Section1
