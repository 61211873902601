import React from 'react';
import './ServicesDetails.css';
import {Row, Col} from 'react-bootstrap';
import {IoCheckmarkCircleOutline} from 'react-icons/io5';
import {motion} from 'framer-motion';

function Section2() {
  const variants = {
    visible: { opacity: 1, transition:{duration: 0.7, delay: 1} },
    hidden: { opacity: 0,  },
  };
  return (
    <div className="servicesdetails-section2-body">
        <Row>

            <Col xs={12} sm={12} md={12} lg={6}>
            <motion.div className="process-section2-content"
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:-50}
            }}
            >
                <div className="process-section2-title">
                Why Choose Mobius
                </div>
                <div className="process-section2-text">
                Mobius strives to provide the best resources that suit your business needs.
                </div>

                <div className="tab-value1-place">
                <IoCheckmarkCircleOutline className="value-icon1" /><div className="tab-value1">
               <span className="bold">Skilled talent, ready to go.</span>   Whatever your needs, Mobius is willing
                to provide a candidate to match it. Experienced professionals can
                be found starting today,
                either remote or onsite.
                </div> <br></br>
                </div>

                <div className="tab-value1-place">
                <IoCheckmarkCircleOutline className="value-icon1"/> <div className="tab-value1">
                <span className="bold">We help you provide the  best.</span>  We really appreciate the satisfaction
                you get. Connect with our team and we can handle the hiring
                process for you.
                </div><br></br>
                </div>

                <div className="tab-value1-place">
                <IoCheckmarkCircleOutline className="value-icon1" /><div className="tab-value1">
                <span className="bold">Diverse talent with complementary abilities.</span>  Until now there are
                hundreds of professional IT talents under the Mobius banner,
                equipped with the most updated tech stack.
                </div>

                </div>


            </motion.div>

            </Col>

            <Col xs={12} sm={12} md={12} lg={6}>
            <motion.div className="process-section3-img-place"
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x:0},
              hidden: { opacity: 0, x:50}
            }}
            >
            </motion.div>
            </Col>

        </Row>

    </div>
  )
}

export default Section2
