import React from 'react';
import './Portfolio.css';
import Header from '../../Components/Navbar/Header';
import {Link} from 'react-router-dom';
import Section1 from './Components/Section1/Section1.js';
import AboutSection3 from '../About/Components/Section3/Section3';
import Footer from '../../Components/Footer/Footer';
import {motion} from 'framer-motion';
import CountUp from 'react-countup';
import ClientLight from './Components/Section2/ClientLight';


function Portfolio() {
  const variants = {
    visible: { opacity: 1, transition:{duration: 0.7, delay: 1} },
    hidden: { opacity: 0,  },
  };

  const variants1 = {
    visible: { opacity: 1, x:0, transition:{duration: 0.7, delay: 1} },
    hidden: { opacity: 0, x:50  },
  };

  const variants2 = {
    visible: { opacity: 1, x:0, transition:{duration: 0.7, delay: 1} },
    hidden: { opacity: 0, x:-50  },
  };


  return (
    <div>
      <Header />
      <div className="portfolio-hero">
        <motion.div className="portfolio-hero-link" initial="hidden" animate="visible" variants={variants}>
          <Link to="/" className="hero-link">Home</Link>
          /  Our Portfolio
        </motion.div>
        <motion.div className="portfolio-hero-title" initial="hidden" animate="visible" variants={variants1}>
        More Than <CountUp end={20} duration={5} enableScrollSpy={true} />+ Satisfied Clients
        </motion.div>
        <motion.div className="portfolio-hero-text" initial="hidden" animate="visible" variants={variants2}>
        Providing our best products and services are essential for our clients satisfaction and success.
Scroll to see some of our delighted clients:
        </motion.div>
        </div>

        <Section1 />
        <ClientLight />
        <AboutSection3 />
        <Footer />


    </div>
  )
}

export default Portfolio
