import React from 'react';
import './Section3.css';
import { Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';

function Section3() {
  return (
    <div>
        <div className="about-section3-body">

            <motion.div className="about-section3-title" initial="hidden"
                whileInView="visible"
                transition={{ duration: 0.8 }}
                variants={{
                  visible: { opacity: 1, y:0},
                  hidden: { opacity: 0, y:50}
                }}>
            We value your feedback and are here to assist you. Contact us by phone, email, or by completing the form on this page for any inquiries or concerns.
            </motion.div>



        </div>

        <div className="about-section3-bodybottom">
            <div className="about-section3-overlay">
               <motion.div className="about-section3-overlay-title" initial="hidden"
                whileInView="visible"
                transition={{ duration: 0.8 }}
                variants={{
                  visible: { opacity: 1, x:0},
                  hidden: { opacity: 0, x:-50}
                }}>Get a free consultation for your case</motion.div>
                <motion.div initial="hidden"
                whileInView="visible"
                transition={{ duration: 0.8 }}
                variants={{
                  visible: { opacity: 1, x:0},
                  hidden: { opacity: 0, x:50}
                }}>
                <Button className="about-section3-button" as={Link} to="/contact">Contact Us</Button></motion.div>
            </div>
        </div>






    </div>
  )
}

export default Section3
