import React from 'react';
import './Section1.css';
import {Row, Col} from 'react-bootstrap';
import {AiOutlineFundProjectionScreen} from 'react-icons/ai';
import {IoCheckmarkCircleOutline} from 'react-icons/io5';
import {motion} from 'framer-motion';

function ProcessContent1() {
  return (
    <motion.div className="process-tab-content" initial="hidden"
    whileInView="visible"
    transition={{ duration: 0.8 }}
    variants={{
      visible: { opacity: 1, y:0},
      hidden: { opacity: 0, y:5}
    }}>
      <Row noGutters={true}>
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
        <div className="process-tabcontent-title">
        Application
        </div>

        <div className="process-tabcontent-text">
        The initial initiation stage where we determine the technology used, how to work, and the level of expertise required
        </div>

       
        <div className="display">
          <IoCheckmarkCircleOutline className="value-icon" />
        <div className="tab-value-1"> Tech Stack (Programming Language, Database, etc)</div>
        </div>
          
          <div className="display">
            <IoCheckmarkCircleOutline className="value-icon"/>
         <div className="tab-value-1"> Onsite, Remote, or Hybrid working approach</div>
          </div>
          
          <div className="display">
            <IoCheckmarkCircleOutline className="value-icon" />
            <div className="tab-value-1"> Assessing level of Experience</div>
          </div>

        
        </Col>


        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
        <div className="process-tab-icon-place">
        <AiOutlineFundProjectionScreen className="process-tab-icon" />
        </div>
        </Col>

      </Row>

    </motion.div>
  )
}

export default ProcessContent1
