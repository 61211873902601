import React from 'react';
import './Section1.css';
import {Row, Col} from 'react-bootstrap';
import {BiCode} from 'react-icons/bi';
import {IoCheckmarkCircleOutline} from 'react-icons/io5';
import {motion} from 'framer-motion';

function ProcessContent3() {
  return (
    <motion.div className="process-tab-content" initial="hidden"
    whileInView="visible"
    transition={{ duration: 0.8 }}
    variants={{
      visible: { opacity: 1, y:0},
      hidden: { opacity: 0, y:5}
    }}>
      <Row noGutters={true}>
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
        <div className="process-tabcontent-title">
        Test & Interview
        </div>


        <div className="process-tabcontent-text">
        Mobius conducts competency tests and interviews to determine the candidate's proficiency level. More than that, Mobius also ensures references and background checks on candidates. The screening process ends with conducting trials within a project to assess their performance and quality
        </div>

        <div className="display">
          <IoCheckmarkCircleOutline className="value-icon" />
        <div className="tab-value"> Low performance, bad attitude, health issue within a certain
        period can be replaced</div> 
        </div>

        
        <div className="display">
          <IoCheckmarkCircleOutline className="value-icon"/>
         <div className="tab-value">
           We will send you a performance report once a month</div>
        </div>


        </Col>


        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
        <div className="process-tab-icon-place">
        <BiCode className="process-tab-icon" />
        </div>
        </Col>

      </Row>

    </motion.div>
  )
}

export default ProcessContent3
